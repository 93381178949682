import React from "react";
import { connect } from "react-redux";
import { CertificateDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Displays, Forms, Modals } from "components";

class CertificateDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    // if (this.props.router.params.id !== "create") {
    this.fetchData();
    // } else {
    //   this.setState({
    //     isLoading: false,
    //     isBgLoading: false,
    //   });
    // }
  }

  fetchData = async () => {
    if (this.props.router.params.id === "create") {
      let res = await Promise.all([
        portalService.GET_ACTIVITY_FORM_LIST(
          `?pagination=false&is_certificate_ready=true`
        ),
      ]);
      if (res) {
        let actOptionTemp = res[0].data.docs.map((e) => ({
          ...e,
          label: e.name,
          value: e._id,
        }));
        this.setState({
          activitiOption: actOptionTemp,
          isLoading: false,
          isBgLoading: false,
        });
      }
    } else {
      let res = await Promise.all([
        portalService.GET_CERTIFICATE_DETAIL(this.props.router.params.id),
        portalService.GET_ACTIVITY_FORM_LIST(
          `?pagination=false&is_certificate_ready=true`
        ),
      ]);
      if (res) {
        let actOptionTemp = res[1].data.docs.map((e) => ({
          ...e,
          label: e.name,
          value: e._id,
        }));
        if (res[0].data.activity_ref_id) {
          actOptionTemp.push({
            label: res[0].data.activity_name,
            value: res[0].data.activity_ref_id,
          });
        }
        this.setState({
          initialValues: res[0].data,
          activitiOption: actOptionTemp,
          isLoading: false,
          isBgLoading: false,
        });
      }
    }
  };

  // FLOW DELETE
  // =============================
  // =============================
  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };
  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_CETIFICATE_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      toast.success("ลบสำเร็จ");
      this.handleNextStep();
    } else {
      toast.error("ลบไม่สำรเร็จ");
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // FLOW SUBMIT
  // =============================
  // =============================
  handleSubmit = async (values) => {
    const { activitiOption } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let find = activitiOption.find((e) => e.value === values.activity_ref_id);
    let params = {
      ...values,
      activity_ref_id: find.value,
      activity_name: find.label,
    };
    let res =
      this.props.router.params.id === "create"
        ? await portalService.POST_CERTIFICATE_DETAIL(params)
        : await portalService.PUT_CERTIFICATE_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      let filesTemp = [];
      filesTemp = [...values.img_file.filter((e) => e.isNewUpload)];
      if (filesTemp.length === 0) {
        toast.success("อัพเดตสำเร็จ");
        this.handleCloseModal();
        this.handleNextStep();
      } else {
        this.handleUploadContentMedia(res.data._id, filesTemp);
      }
    } else {
      this.handleCloseModal();
      toast.error("อัพเดตไม่สำเร็จ");
    }
  };
  handleUploadContentMedia = (certificateID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("certificate_id", certificateID);
            bodyFormData.set("type", e.fieldName);
            bodyFormData.append("image", e);
            let res = await portalService.POST_CERTIFICATE_UPLOAD(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.handleCloseModal();
      this.setState({
        isBgLoading: false,
      });
      toast.success("อัพเดจสำเร็จ");
      this.handleNextStep();
    });
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleNextStep = () => {
    this.props.router.navigate(ROUTE_PATH.CERTIFICATE);
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      initialValues,
      isBgLoading,
      activitiOption,
      renderModal,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "certificate");
    return (
      <CertificateDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading && !activitiOption ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <Forms.CertificateForm
            activitiOption={activitiOption}
            initialValues={initialValues}
            permission={permission}
            onCancel={this.handleCancel}
            onDelete={this.handleDelete}
            onSubmit={this.handleSubmit}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </CertificateDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CertificateDetailContainer));
