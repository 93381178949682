import { useState } from "react";
import { BtnCertificateGenerateWidgetStyled } from "./styled";
import { toast } from "react-toastify";
import { portalService } from "apiServices";
import { Buttons, Displays, Forms, Modals } from "components";

const BtnCertificateGenerateWidget = ({ certificateID }) => {
  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_renderModal, _setRenderModal] = useState(false);

  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  const _handleClickGenerate = () => {
    _setRenderModal(
      <Forms.CertificateGenerateForm
        onCancel={_handleCloseModal}
        onSubmit={_handleSubmit}
      />
    );
  };

  const _handleSubmit = async (values) => {
    _setIsBgLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("image", values.csv_file[0]);
    bodyFormData.set("certificateID", certificateID);
    let res = await portalService.POST_CERTIFICATE_GENERATE_UPLOAD(
      bodyFormData
    );
    if (res && res.status === 200) {
      _handleCloseModal();
      toast.success("อัพโหลดสำเร็จ");
      _setIsBgLoading(false);
    } else {
      _handleCloseModal();
      toast.error("อัพโหลดไม่สำเร็จ");
      _setIsBgLoading(false);
    }
  };

  return (
    <BtnCertificateGenerateWidgetStyled>
      <Displays.BGLoading visible={_isBgLoading} />
      <Buttons.BgStandard
        theme_export_btn
        label={"Generate"}
        onClick={_handleClickGenerate}
      />
      <Modals.BodyEmpty
        isShowModal={_renderModal}
        onCloseModal={_handleCloseModal}
      >
        {_renderModal}
      </Modals.BodyEmpty>
    </BtnCertificateGenerateWidgetStyled>
  );
};

export default BtnCertificateGenerateWidget;
