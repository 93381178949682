import React from "react";
import { connect } from "react-redux";
import { ActivityFormOfflineDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import * as XLSX from "xlsx";
import moment from "moment";
import momentTZ from "moment-timezone";
import { toast } from "react-toastify";
import { RENDER_CHARACTER_REPLACE_BY_PERMISSION } from "utils/functions/string";
import Linkify from "react-linkify";
import { ACTIVITY_FORM_RENDER_STATUS } from "utils/functions/activityForm";
import { IMG_PATH } from "utils/constants/imgPath";
import { BtnApproveFormWidget, BtnUploadCerificateWidget } from "widgets";
import { Displays, Inputs, Modals, Tables } from "components";

class ActivityFormOfflineDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?pagination=false`);
  }

  fetchData = async (queryString) => {
    let resArr = await Promise.all([
      portalService.GET_ACTIVITY_FORM_DETAIL(this.props.router.params.id),
      portalService.GET_ACTIVITY_FORM_HISTORY_BY_ACTIVITY_LIST(
        this.props.router.params.id,
        queryString
      ),
    ]);
    if (resArr) {
      this.setState({
        data: resArr[0].data,
        activityHistorydata: {
          ...resArr[1],
          docs: resArr[1].data.docs.sort(
            (a, b) => statusSort[a.status] - statusSort[b.status]
          ),
        },
        selectedAmount: resArr[1].data.docs.filter((e) => e.is_selected).length,
        pagination: {
          last_page: resArr[1].data.totalPages,
          current_page: resArr[1].data.page,
        },
        isLoading: false,
        isBgLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleClickGifgPoint = async (permission, e) => {
    if (!permission || !permission.is_update) {
      toast.error("คุณไม่มีสิทธิ์ในการให้คะแนน");
    } else {
      this.setState({
        isBgLoading: true,
      });
      let params = {
        customer_id: e.customer_ref_id,
        activity_id: e.activity_ref_id,
        activity_history_id: e._id,
        member_type: e.member_type,
      };
      let res = await portalService.POST_ACTIVITY_HITORY_SCAN(params);
      if (res && res.status === 200) {
        this.fetchData();
      } else {
        toast.error("ไม่สำเร็จ");
      }
    }
  };

  handleExport = async (permission) => {
    const { data } = this.state;
    const { filterQuery } = this.state;
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;

    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.GET_ACTIVITY_FORM_HISTORY_BY_ACTIVITY_LIST(
      this.props.router.params.id,
      joinQuery
    );
    if (res && res.status === 200) {
      let wb = XLSX.utils.book_new();
      let ws_name = "SheetJS";
      /* make worksheet */
      let ws_data = res.data.docs
        .sort((a, b) => statusSort[a.status] - statusSort[b.status])
        .reduce((result, e, i) => {
          const renderAns = [];
          e.question_list.forEach((f) => {
            f.questions.forEach((g) => {
              renderAns.push(
                g.type === "3"
                  ? g.answer
                    ? IMG_PATH + g.answer
                    : ""
                  : g.answer
              );
            });
          });

          result.push([
            i + 1,
            momentTZ(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
            e.member_type === "1" ? "บุคคล" : "องค์กร",
            `${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: e.personal_first_name,
              action: "export",
            })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
              ...permission,
              inputString: e.personal_last_name,
              action: "export",
            })}`,
            e.personal_email,
            e.check_in,
            ACTIVITY_FORM_RENDER_STATUS(e.status).label,
            e.rejection_reason,
            ...renderAns,
          ]);
          return result;
        }, []);
      let renderQuestionColumn = [];
      if (res.data.docs.length > 0) {
        res.data.docs[0].question_list.forEach((f) => {
          f.questions.forEach((g, h) => {
            renderQuestionColumn.push(`Ans${f.page}/${h + 1}`);
          });
        });
      }
      ws_data.splice(0, 0, [
        "No",
        "Create Date",
        "Register By",
        "Name",
        "Email",
        "Check in",
        "Approve",
        "Reject",
        ...renderQuestionColumn,
      ]);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      let wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `act-report-${data.code}-${data.name}-${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleBgLoading = (e) => {
    this.setState({
      isBgLoading: e,
    });
  };

  render() {
    const { authenRedux } = this.props;
    const {
      isLoading,
      isLoadingTable,
      isBgLoading,
      data,
      activityHistorydata,
      pagination,
      // perPage,
      isShowModal,
      renderModal,
    } = this.state;
    const permission = !authenRedux
      ? false
      : authenRedux?.permission.find((e) => e.menu_key === "activity-form");
    return (
      <ActivityFormOfflineDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <>
            <div className="block_section">
              <div className="title">ข้อมูลผู้เข้าร่วมกิจกรรม</div>
              <div className="body_row">
                <div className="b_left_col">
                  <div className="value_item_wrap">
                    <Displays.LabelValue
                      label={"รหัส"}
                      value={`${data.code}`}
                    />
                  </div>
                  <div className="value_item_wrap">
                    <Displays.LabelValue label={"ชื่อ"} value={data.name} />
                  </div>
                  {data.question_list && data.question_list.length > 0 && (
                    <>
                      <div className="title">คำถาม</div>
                      <div className="body pl_1">
                        {data.question_list &&
                          data.question_list.map((e) =>
                            e.questions.map((f, j) => (
                              <div key={j} className="question_row">
                                <div>
                                  หน้า {e.page} ข่้อ {j + 1}
                                </div>
                                <div
                                  className="question_label"
                                  dangerouslySetInnerHTML={{
                                    __html: f.question,
                                  }}
                                />
                                <div className="question_ans_block">
                                  {f.answer_list.map((g, k) => (
                                    <div key={k} className="ans_label">
                                      {`ตอบ ${k + 1} `}
                                      {g}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="filter_wrap">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={[]}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
                onExport={
                  permission &&
                  (permission.is_export || permission.is_export_condition)
                    ? () => this.handleExport(permission)
                    : false
                }
              />
            </div>
            {isLoadingTable ? (
              <Displays.Loading theme_standard_loading />
            ) : (
              <div className="body_table">
                <Tables.Standard
                  columns={
                    COLUMNS({
                      permission: permission,
                      data: data,
                      handleApproveeSuccess: this.fetchData,
                      handleBgLoading: this.handleBgLoading,
                    })
                    // this.renderColumn(data, permission)
                  }
                  data={activityHistorydata.docs}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            )}
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={() => {
            this.setState({ isShowModal: false });
          }}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ActivityFormOfflineDetailContainerStyled>
    );
  }
}

const statusSort = { 2: 0, 1: 1, 3: 2, 4: 3 };

const COLUMNS = ({
  data,
  permission,
  handleApproveeSuccess,
  handleBgLoading,
}) => {
  let temp = [
    {
      Header: <div style={{ textAlign: "left" }}>Create Date</div>,
      accessor: "created_at",
      Cell: (props) => (
        <div>
          {momentTZ(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Register By</div>,
      accessor: "member_type",
      Cell: (props) => <div>{props.value === "1" ? "บุคคล" : "องค์กร"}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Name</div>,
      accessor: "personal_first_name",
      Cell: (props) => (
        <div>
          {`${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.value,
          })} ${RENDER_CHARACTER_REPLACE_BY_PERMISSION({
            ...permission,
            inputString: props.row.original.personal_last_name,
          })}`}
        </div>
      ),
    },
    {
      Header: <div style={{ textAlign: "left" }}>Email</div>,
      accessor: "personal_email",
      Cell: (props) => <div>{`${props.value || ""}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Phone</div>,
      accessor: "personal_phone_number",
      Cell: (props) => <div>{`${props.value || ""}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Check in</div>,
      accessor: "check_in",
      Cell: (props) => <div>{`${props.value ? "Y" : "N"}`}</div>,
    },
    {
      Header: <div style={{ textAlign: "left" }}>Approved</div>,
      accessor: "action",
      Cell: (props) => {
        return (
          <BtnApproveFormWidget
            id={props.row.original._id}
            value={props.row.original.status}
            onBgLoading={handleBgLoading}
            onSuccess={handleApproveeSuccess}
          />
        );
      },
    },
    {
      Header: <div style={{ textAlign: "left" }}>Reject</div>,
      accessor: "rejection_reason",
      Cell: (props) => <div>{props.value || "-"}</div>,
    },
  ];
  if (data.question_list && data.question_list.length > 0) {
    data.question_list.forEach((e, i) => {
      e.questions.forEach((f, j) => {
        temp.push({
          Header: (
            <div style={{ textAlign: "left" }}>{`Ans${e.page}/${j + 1}`}</div>
          ),
          accessor: `question_list${i + 1}${j + 1}`,
          Cell: (props) => {
            if (!props.row.original.question_list[i]?.questions[j]) {
              return "-";
            } else {
              switch (props.row.original.question_list[i].questions[j].type) {
                case "3":
                  return !props.row.original.question_list[i].questions[j]
                    .answer ? (
                    "-"
                  ) : (
                    <a
                      href={
                        IMG_PATH +
                        props.row.original.question_list[i].questions[j].answer
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  );
                case "5":
                  return !props.row.original.question_list[i].questions[j]
                    .answer
                    ? "-"
                    : momentTZ(
                        props.row.original.question_list[i].questions[j].answer
                      )
                        .tz("Asia/Bangkok")
                        .format("DD/MM/YYYY");
                default:
                  return (
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {props.row.original.question_list[i].questions[j].answer}
                    </Linkify>
                  );
              }
            }
          },
        });
      });
    });
  }
  if (data.is_certificate) {
    temp.push({
      Header: <div style={{ textAlign: "left" }}>Certificate</div>,
      accessor: "certificate_file",
      Cell: (props) => (
        <div>
          <BtnUploadCerificateWidget
            acvityFormHistoryID={props.row.original._id}
            value={props.value}
            onBgLoading={handleBgLoading}
            onSuccess={handleApproveeSuccess}
          />
        </div>
      ),
    });
  }

  return temp;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityFormOfflineDetailContainer));
